import React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "components/layout/default";
import FeatureItem from "components/blog-item";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import radiusCapBlackTopSmImage from "images/radius-cap-black-top-sm.svg";
import radiusCapBlackBottomSmImage from "images/radius-cap-black-bottom-sm.svg";
import penTestImage1 from "images/ciso/vCISO-Image01.svg";
import penTestImage2 from "images/ciso/vCISO-Image02.svg";
import sponsorsImg from "images/ciso/vCISO-Certificates.svg";
import wardenImage from "images/ciso/vCISO-MainLogo.png";
import iconImage1 from "images/ciso/vCISO-Icon01.svg";
import iconImage2 from "images/ciso/vCISO-Icon02.svg";
import iconImage3 from "images/ciso/vCISO-Icon03.svg";
import iconImage4 from "images/ciso/vCISO-Icon04.svg";
import iconImage5 from "images/ciso/vCISO-Icon05.svg";
import iconImage6 from "images/ciso/vCISO-Icon06.svg";
import Img from "components/image";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import style from "styles/pages/products.module.css";
import styles from "styles/pages/solutions.module.css";

function CisoPage(props) {
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  let mainImageRef = React.useRef();

  React.useEffect(() => {
    setMainImageLoaded(mainImageRef.current.complete);
  }, [mainImageRef]);

  function handleMainImageLoad() {
    setMainImageLoaded(true);
  }

  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "64vh";
    return (
      <div
        className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
        style={{ height }}/>
    );
  }

  function renderSection1() {
    return (
      <section className="relative min-h-screen">
        <div className="container mx-auto px-8 pt-4 pb-16 md:py-32 z-10 relative">
          <div className="text-center px-8 md:px-0">
            <img
              ref={mainImageRef}
              className="inline-block pointer-events-none"
              src={wardenImage}
              width={340}
              alt=""
              onLoad={handleMainImageLoad}/>
          </div>
          <div className="relative flex">
            {renderVerticalLine()}
            <div className="md:w-1/2 flex-none"/>
            <div className="ml-0 md:ml-16">
              <h3 className="font-bold text-4xl lg:text-6xl leading-tight mt-4">
                vCISO
              </h3>
              <p>
              Accelerate secure innovation when you outsource your security to Asia&apos;s  leading cybersecurity team.
              </p>
            </div>
          </div>
        </div>
        <Img
          {...IMAGE_BACKGROUND_PROPS}
          objectFit="contain"
          alt="Background"
          fluid={props.data.backgroundImage.childImageSharp.fluid}/>
      </section>
    );
  }

  function renderSection2() {
    const subHeaderText = (
      <h3 className="font-serif italic text-2xl sm:text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
        vCISO is the preferred solution for organizations to maximize their
        security investment and achieve business goals
      </h3>
    );
    return (
      <section>
        <div className="container mx-auto px-8 pt-32 lg:py-0 relative text-black">
          {subHeaderText}
        </div>
      </section>
    );
  }

  function renderSection3() {
    return (
      <section className="relative bg-white text-black flex justify-center text-black bg-white">
        <div className="flex flex-wrap container pt-40">
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage1}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                A FULL TEAM OF QUALIFIED CISOs
              </h2>
              <p className="mt-2">
                Horangi’s certified consultants combine decades of cybersecurity
                strategy and field experience in the largest enterprises and
                National Security agencies worldwide.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage2}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                EVERYTHING &amp; MORE FROM A CISO
              </h2>
              <p className="mt-2">
                Board and executive briefings, security organizational planning,
                software development consulting, policy review, and other
                ancillary security duties.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage3}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                EXTENSIVE SPECTRUM OF SOLUTIONS
              </h2>
              <p className="mt-2">
                Multi-faceted security expertise and services available for
                organizations in the cloud or on-prem.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage4}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                MULTI-INDUSTRY ASSISTANCE
              </h2>
              <p className="mt-2">
                Meet the most recognized regulations by leveraging our
                experience helping organizations of all sizes and industries in
                Asia.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage5}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                RISK-BASED CONSULTATION
              </h2>
              <p className="mt-2">
                Strategic approach and concise reports provide clear visibility
                of action priorities to top management.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage6}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                SCALABLE AND IMMEDIATE
              </h2>
              <p className="mt-2">
                The flexibility of vCISO means you are not tied down to
                commitments, giving you more control of resources.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function renderSection4() {
    return (
      <section className="relative bg-white p-20 md:p-64 mb-40 mt-20">
        <p className="text-black font-bold text-base md:text-xl text-center mb-5">
          CERTIFICATIONS
        </p>
        <img src={sponsorsImg} className="mx-auto" alt=""/>
      </section>
    );
  }

  function renderSection7() {
    return (
      <section className="relative text-white sm:mb-32">
        <img
          src={radiusCapBlackTopSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none relative"/>
        <div style={{marginTop: -1}} className="bg-black relative z-10 pb-16 sm:pb-0 sm:-mt-px">
          <div className="container mx-auto px-8 pt-16 md:pt-32 z-10 relative">
            <div className="sm:flex">
              <div className="sm:w-4/5 mt-64 md:mt-0">
                <div>
                  <h2 className="font-bold text-xl leading-tight uppercase">
                    A COMPETITIVE EDGE AT YOUR DISPOSAL
                  </h2>
                  <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                    Solidify Your Strategy With Horangi’s Perspective
                  </h3>
                  <p className="w-4/5 mt-8 mb-16 md:pr-20">
                    Instantly boost your security team. The highly qualified
                    Horangi vCISO consultant team gives you fast access to
                    security expertise that has empowered many of Asia’s
                    industry leaders in Tech, Crypto, and Banking. Having this
                    unique outsider perspective means you are privy to
                    actionable insights about your blind spots to course-correct
                    and improve your innovation strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.computerImage} ${styles.ciso_feature1_wrapper}`}>
            <img
              src={penTestImage1}
              className={styles.ciso_feature1_image}
              alt=""/>
          </div>
          <div>
            <FeatureItem
              image={props.data.featureImage.childImageSharp.fluid}
              title="Leading Cyber Security Experts In Asia"
              description="Horangi recruits, trains, and deploys the world’s finest Cyber professionals and products to keep our clients safe."
              url="/blog/leading-cybersecurity-experts-in-asia"/>
          </div>
          <div
            className={`${styles.hardenSecuritySection} ${styles.ciso_feature2_wrapper}`}>
            <div className="mx-auto pt-10 sm:pb-0 md:pt-32 z-10 relative">
              <div className="sm:flex justify-end">
                <div className="sm:w-full md:w-1/2 p-10 md:pl-20 md:pr-20 pb-0">
                  <h2 className="font-bold text-xl leading-tight uppercase">
                    THE MARK OF A MATURE ORGANIZATION
                  </h2>
                  <h3 className="font-serif italic text-4xl xl:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                    Turn Your Cost Savings Into Transformative Solutions
                  </h3>
                  <p className="mt-8 mb-16">
                    vCISO provides cost-efficiency and resource flexibility that
                    are invaluable to fast-growing organizations. Leverage
                    Horangi’s established processes and technology stack to
                    identify and address your top regulatory priorities. Tackle
                    your compliance risks faster with Horangi’s solution suite
                    while you leave the comprehensive management reports to
                    Horangi.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={penTestImage2}
              alt=""
              className={`absolute ${styles.ciso_feature2_image}`}/>
          </div>
        </div>
        <img
          style={{marginTop: -1}}
          src={radiusCapBlackBottomSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none relative bg-white"/>
      </section>
    );
  }

  function renderSection8() {
    return (
      <section
        className={`relative text-black ${styles.bottomContactUs} ${styles.ciso_contact_us_wrapper}`}>
        <div className={`container mx-auto z-10`}>
          <h3 className="text-lg text-center leading-tight mb-6 font-bold uppercase">
            Speak to a Horangi Tiger today
          </h3>
          <p className="max-w-xl mx-auto text-center whitespace-pre-wrap p-5 md:p-0">
            Start a smarter security journey by reaching out to Horangi. We are
            happy to extend to you the first consultation at no cost so you get
            to experience the Horangi value.
          </p>
          <div className="text-center">
            <a href={"/contact"} className="btn btn-orange mt-6 inline-block">
              Contact Us
            </a>
          </div>
        </div>
      </section>
    );
  }

  return (
    <DefaultLayout
      location={props.location}
      title="Horangi vCISO"
      description="vCISO is the preferred solution for organizations to maximize their security investment and achieve business goals. Scale your innovation with Asia’s leading cybersecurity team at your side.">
      <div className={layoutStyle.headerPlaceholder}/>
      {renderSection1()}
      <img
        src={radiusTopCapImage}
        alt="Background"
        className="w-full z-1 pointer-events-none"/>
      <div className="relative z-10 -mt-32 overflow-hidden">
        <div
          className="bg-white absolute w-full"
          style={{ top: "127px", bottom: "127px" }}/>
        {renderSection2()}
        {renderSection3()}
        {renderSection4()}
        {renderSection7()}
        {renderSection8()}
      </div>
      <img
        src={radiusBottomCapImage}
        alt="Background"
        className="w-full z-1 -mt-32 pointer-events-none"/>
      <div className="pb-48"/>
    </DefaultLayout>
  );
}

export default React.memo(CisoPage);

export const QUERY = graphql`
  query SolutionsCisoPage {
    pageContent: file(
      relativePath: { eq: "solution-pages/penetrationtesting.json" }
    ) {
      childProductPagesJson {
        title
        description
      }
    }
    backgroundImage: file(
      relativePath: { eq: "background-products-item.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureImage: file(relativePath: { eq: "blog_innovate_without_fear.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
